import { Button, Link, Modal } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { Consumer } from 'types/Consumer';

type Props = {
  activeConsumer: Consumer | null;
  onClose: () => void;
};

export const CustomerActionsModal = ({ activeConsumer, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal open={Boolean(activeConsumer)} onClose={onClose}>
      <Modal.Title>{activeConsumer?.name}</Modal.Title>
      <Modal.Actions>
        <Link.ModalAction
          to="/orders/add/delivery-date"
          state={{
            consumer: activeConsumer
          }}
        >
          {t('common:MakeNewOrder')}
        </Link.ModalAction>
        <Link.ModalAction to={`tel:${activeConsumer?.phone}`}>
          {t('common:Call')}
        </Link.ModalAction>
        <Link.ModalAction to={`mailto:${activeConsumer?.emails.join(',')}`}>
          {t('common:Email')}
        </Link.ModalAction>
        <Button.ModalAction onClick={onClose} color="error" close>
          {t('common:close')}
        </Button.ModalAction>
      </Modal.Actions>
    </Modal>
  );
};
