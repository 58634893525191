import { useFormContext, useWatch } from '@dagens/frontend-forms';
import { useTranslation } from '@dagens/frontend-i18n';
import { Icon } from '@dagens/carrot';
import { createSearchParams } from 'react-router-dom';
import { ReqType } from '../../../../utils/REQ';
import { ActionButton } from '../../../../components/action-button';
import { AddCustomerDeliveryFormValues } from '.';

type Props = {
  saveReq: ReqType;
  onSubmit: (data: AddCustomerDeliveryFormValues) => void;
};

export const AddCustomerContactNextButton = ({ onSubmit, saveReq }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<AddCustomerDeliveryFormValues>();
  const formData = useWatch<AddCustomerDeliveryFormValues>();
  const search = formData.customerName
    ? createSearchParams({
        query: formData.customerName
      }).toString()
    : undefined;
  return (
    <ActionButton.Save
      onClick={handleSubmit(onSubmit)}
      saveReq={saveReq}
      redirectTo={{
        pathname: '/customers',
        search
      }}
    >
      {t(
        'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
      )}
      <Icon icon="forward" size="small" />
    </ActionButton.Save>
  );
};
